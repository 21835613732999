
import { Subject } from 'rxjs';

import { BaseClass } from '../../shared/base.class';
import { HashMap } from '../../shared/utilities/types.utilities';
import { ServiceManager } from './service-manager.class';

export type DataClassEventType = 'value_change' | 'item_saved' | 'reset' | 'other';

export interface IDataClassEvent {
    type: DataClassEventType;
    metadata: HashMap;
}

export const BASE_MUTABLE_FIELDS = ['name', 'email', 'recurrence'] as const;
type BaseMutableTuple = typeof BASE_MUTABLE_FIELDS;
export type BaseMutableFields = BaseMutableTuple[number];

export class BaseDataClass extends BaseClass {
    /** Unique Identifier of the object */
    public readonly id: string;
    /** Human readable name of the object */
    public readonly name: string;
    /** Email address associated with the object */
    public readonly email: string;
    /** Subject for change events to the class object */
    public readonly changeEvents = new Subject<IDataClassEvent>();
    /** Map of pending changes to the object */
    protected _changes: HashMap = {};
    /** Map of local property names to server ones */
    protected _server_names: HashMap<string> = {};

    /** Service associated with BaseDataClass */
    protected get _service(): any {
        return ServiceManager.serviceFor(BaseDataClass);
    }

    constructor(raw_data: HashMap) {
        super();
        this.id = raw_data.id || raw_data.zone_id || raw_data.email || '';
        this.name = raw_data.name || '';
        this.email = (raw_data.email || '').toLowerCase();
        this._changes = {};
    }

    /** Whether the form has changes */
    public get has_changes(): boolean {
        return Object.keys(this._changes).length > 0;
    }

    /** Map of currently unsaved changes to the state */
    public get changes(): HashMap {
        return JSON.parse(JSON.stringify(this._changes));
    }

    /**
     * Store new value for given field
     * @param key
     * @param value
     */
    public storePendingChange(key: string, value: any): this {
        this._changes[key as any] = value;
        this.emit('value_change', { key, value });
        return this;
    }

    public clearPendingChanges(): void {
        delete this._changes;
        this._changes = {};
        this.emit('reset');
    }

    /**
     * Save pending changes to server
     */
    public save(force: boolean = false): Promise<BaseDataClass> {
        if ((this.has_changes || force) && this._service) {
            const form = this.toJSON();
            return new Promise((resolve, reject) => {
                this.id
                    ? this._service.update(this.id, form)
                        .then((d) => { this.emit('item_saved', d); resolve(d) }, _ => reject(_))
                    : this._service.add(form)
                        .then((d) => { this.emit('item_saved', d); resolve(d) }, _ => reject(_));
            });
        } else {
            Promise.reject('No changes have been made');
        }
    }

    /**
     * Delete this item from the server
     */
    public delete(): Promise<void> {
        if (this.id) {
            return this._service.delete(this.id);
        }
    }

    /**
     * Run task for this item on the service
     * @param task_name Name of the task
     * @param parameters Parameters to pass to the task request
     */
    public runTask(task_name: string, parameters: HashMap): Promise<void> {
        if (this.id) {
            return this._service.task(this.id, task_name, parameters);
        }
    }
    /**
     * Convert object into plain object
     */
    public toJSON(this: BaseDataClass, with_changes: boolean = true): HashMap {
        const obj: any = { ...this };
        // Remove local private members
        delete obj._service;
        delete obj._changes;
        delete obj.changeEvents;
        // Remove parent private members
        delete obj._timers;
        delete obj._intervals;
        delete obj._subscriptions;
        delete obj._server_names;
        delete obj._initialised;
        // Convert remaining members to be public
        const keys = Object.keys(obj);
        for (const key of keys) {
            if (key[0] === '_') {
                const new_key = this._server_names[key.substr(1)] || key.substr(1);
                obj[new_key] = obj[key];
                delete obj[key];
            } else if (obj[key] === undefined) {
                delete obj[key];
            }
        }
        return with_changes ? { ...obj, ...this._changes } : obj;
    }

    /**
     * Emits change event
     * @param type Type of change that has occurred
     * @param metadata Supporting metadata for the event
     */
    public emit(type: DataClassEventType, metadata?: HashMap): void {
        this.changeEvents.next({ type, metadata })
    }

    /**
     * Make a copy of this object
     */
    public clone(): BaseDataClass {
        return new BaseDataClass(this);
    }

    /**
     * Make a copy of this object without identification data
     */
    public duplicate(): BaseDataClass {
        return new BaseDataClass({ ...this, id: null, email: null });
    }
}
