import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { DialogEvent, Identity } from '../../shared/utilities/types.utilities';
import { BaseDirective } from 'src/app/shared/base.directive';
import { BookingRecurrenceDetails } from 'src/app/services/data/bookings/booking.interfaces';

export interface RecurrenceModalData {
    /** Current recurrence details */
    details: BookingRecurrenceDetails;
    /** Currently set date for the booking */
    date: number;
}

export const RECURRENCE_METADATA = {
    height: 'auto',
    width: '24em',
    maxHeight: 'calc(100vh - 2em)',
    maxWidth: 'calc(100vw - 2em)'
};

@Component({
    selector: 'recurrence-modal',
    templateUrl: './recurrence-modal.component.html',
    styleUrls: ['./recurrence-modal.component.scss']
})
export class RecurrenceModalComponent extends BaseDirective implements OnInit {
    /** Emitter for user action on the modal */
    @Output() public event = new EventEmitter<DialogEvent>();
    /** Form fields for recurrence */
    public form: FormGroup;
    /** Loading state */
    public loading: string;
    /** Available recurrence periods */
    public periods: Identity[] = [
        { id: 'None', name: 'None' },
        { id: 'Daily', name: 'Daily' },
        { id: 'Weekly', name: 'Weekly' },
        { id: 'Biweekly', name: 'Biweekly' },
        { id: 'Monthly', name: 'Monthly' },
    ];

    constructor(
        @Inject(MAT_DIALOG_DATA) private _data: RecurrenceModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        this.form = new FormGroup({
            period: new FormControl(this._data.details.period || 'none'),
            end: new FormControl(this._data.details.end || this._data.date, [Validators.required]),
        });
    }

    /**
     * User confirmation of the content of the modal
     */
    public save() {
        this.form.markAllAsTouched();
        /* istanbul ignore else */
        if (this.form.valid) {
            this.loading = 'Adding recurrence data to booking...';
            this.event.emit({ reason: 'done', metadata: { ...this._data.details, ...this.form.value } });
        }
    }
}
