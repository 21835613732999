import { Injectable } from '@angular/core';
import { ComposerService } from '@placeos/composer';
import { EngineHttpClient } from '@placeos/ts-client';

import { toQueryString } from 'src/app/shared/utilities/api.utilities';
import { ServiceManager } from '../service-manager.class';
import { Calendar } from './calendar.class';
import { CalenderAvailabilityParams } from './calendar.interfaces';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {
    private _route: string = '/api/staff/v1/calendars';

    private get _http(): EngineHttpClient {
        return this._composer.http;
    }

    constructor(private _composer: ComposerService) {
        ServiceManager.setService(Calendar, this);
    }

    /**
     * List available calendars
     * @param q Query parameters to pass to the request
     */
    public availability(q: CalenderAvailabilityParams = {}): Promise<Calendar[]> {
        return new Promise((resolve, reject) => {
            const query = toQueryString(q);
            const url = `${this._route}/availability${query ? '?' + query : ''}`;
            let items = [];
            this._http.get(url).subscribe(
                (list) => items = list.map(cal => new Calendar(cal)),
                (err) => reject(err),
                () => resolve(items)
            )
        });
    }
}
