<header>
    <h3 mat-dialog-title>{{ title || 'Confirm' }}</h3>
    <button mat-icon-button mat-dialog-close>
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'close' }"></app-icon>
    </button>
</header>
<div class="body">
    <app-icon *ngIf="icon" [icon]="icon"></app-icon>
    <div class="content">
        <p>{{ content || 'Are you sure?' }}</p>
    </div>
</div>
<footer>
    <button mat-button class="inverse" mat-dialog-close>{{ cancelText || 'Cancel' }}</button>
    <button mat-button name="accept" (tapped)="accept()">{{ okText || 'Ok' }}</button>
</footer>
