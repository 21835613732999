<header>
    <h3 mat-dialog-title>Add External Attendee</h3>
    <button mat-icon-button mat-dialog-close *ngIf="!loading">
        <app-icon [icon]="{ type: 'icon', class: 'material-icons', content: 'close' }"></app-icon>
    </button>
</header>
<div class="body" *ngIf="!loading; else load_state">
    <user-form [form]="form"></user-form>
</div>
<footer *ngIf="!loading">
    <button mat-button class="inverse" mat-dialog-close>Cancel</button>
    <button mat-button (tapped)="saveChanges()">Save</button>
</footer>
<ng-template #load_state>
    <div class="body">
        <div class="info-block">
            <div class="icon">
                <mat-spinner diameter="32"></mat-spinner>
            </div>
            <div class="text">
                Saving attendee...
            </div>
        </div>
    </div>
</ng-template>

