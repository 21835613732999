import { Component, OnInit, Output, EventEmitter, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { BaseDirective } from '../../shared/base.directive';
import { ApplicationIcon } from '../../shared/utilities/settings.interfaces';
import { DialogEvent } from '../../shared/utilities/types.utilities';

export interface ConfirmModalData {
    /** Title of the modal */
    title: string;
    /** Contents of the modal */
    content: string;
    /** Text displaed on the confirmation button */
    okText: string;
    /** Text displaed on the cancel button */
    cancelText: string;
    /** Icon to display on the modal */
    icon: ApplicationIcon;
}

@Component({
    selector: 'confirm-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent extends BaseDirective implements OnInit {
    /** Title of the confirm modal */
    public title: string;
    /** Body of the confirm modal */
    public content: string;
    /** Display text on the confirm button */
    public okText: string;
    /** Display text on the confirm button */
    public cancelText: string;
    /** Display icon properties */
    public icon: ApplicationIcon;

    constructor(
        public ref: MatDialogRef<ConfirmModalComponent>,
        @Inject(MAT_DIALOG_DATA) private _data: ConfirmModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        const data = this._data;
        if (data) {
            this.title = data.title || 'Confirm';
            this.content = data.content || 'Are you sure?';
            this.okText = data.okText || 'Ok';
            this.cancelText = data.cancelText || 'Cancel';
            this.icon = data.icon;
        }
    }

    /**
     * User confirmation of the content of the modal
     */
    public accept() {
        this.ref.close('done');
    }
}
