import { Component, OnInit, Input, SimpleChanges, OnChanges, Inject } from '@angular/core';
import { Router } from '@angular/router';

import { BaseDirective } from 'src/app/shared/base.directive';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { User } from 'src/app/services/data/users/user.class';

@Component({
    selector: 'schedule-view-attendees',
    templateUrl: './view-attendees-modal.component.html',
    styleUrls: ['./view-attendees-modal.component.scss']
})
export class ViewAttendeesModalComponent extends BaseDirective implements OnChanges, OnInit {
    public attendees: User[];

    constructor(@Inject(MAT_DIALOG_DATA) private _data: { attendees: User[] }) {
        super();
    }

    public ngOnChanges() {

    }

    public ngOnInit() {
            this.attendees = this._data.attendees;
    }

}
