import { Component, ViewEncapsulation } from '@angular/core';
import { detectIE } from './shared/utilities/general.utilities';
import { ApplicationService } from './services/app.service';
import { OrganisationService } from './services/data/organisation/organisation.service';
import { SpacesService } from './services/data/spaces/spaces.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./styles/app.component.scss', './styles/custom-element.styles.scss', './styles/native-element.styles.scss'],
    encapsulation: ViewEncapsulation.None
})
export class AppComponent {
    constructor(private _app: ApplicationService, private _org: OrganisationService, private _spaces: SpacesService) {
        if (detectIE() && detectIE() < 12) {
            location.href = `${location.origin}${location.pathname}assets/not-supported.html`;
            return;
        }
    }
}
