import { Injectable, ApplicationRef, NgZone } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { SwUpdate } from '@angular/service-worker';
import { first } from 'rxjs/operators';

import { MatSnackBar } from '@angular/material/snack-bar';

import { ComposerService } from '@placeos/composer';
import { PlaceOSOptions } from '@placeos/ts-client';
import { GoogleAnalyticsService } from '@acaprojects/ngx-google-analytics';

import { Observable, BehaviorSubject, Subject, Subscription } from 'rxjs';

import { BaseClass } from '../shared/base.class';
import { ConsoleStream, SettingsService } from './settings.service';
import { ApplicationLoadingState } from '../shared/utilities/types.utilities';

import { HotkeysService } from './hotkeys.service';
import { ComposerSettings, ApplicationIcon } from '../shared/utilities/settings.interfaces';
import { ServiceManager } from './data/service-manager.class';

declare global {
    interface Window {
        application: ApplicationService;
        mock: {
            enabled: boolean;
            backend: any;
        };
    }
}

@Injectable({
    providedIn: 'root'
})
export class ApplicationService extends BaseClass {
    /** Map of state variables for Service */
    protected _subjects: {
        [key: string]: BehaviorSubject<any> | Subject<any>;
    } = {};
    /** Map of observables for state variables */
    protected _observers: { [key: string]: Observable<any> } = {};
    /** Whether the application has stablised */
    private _stable: boolean;

    private _navTitle: string;

    /** Whether the application has stablised */
    public get is_stable(): boolean {
        return this._stable || false;
    }

    constructor(
        private _app_ref: ApplicationRef,
        private _zone: NgZone,
        private _title: Title,
        private _cache: SwUpdate,
        private _settings: SettingsService,
        private _composer: ComposerService,
        private _analytics: GoogleAnalyticsService,
        private _hotkeys: HotkeysService,
        private _snackbar: MatSnackBar
    ) {
        super();
        ServiceManager.setService(ApplicationService, this);
        this.set('system', null);
        this.set('title', 'Home');
        this._app_ref.isStable.pipe(first(_ => _)).subscribe(() => {
            this._zone.run(() => {
                this._stable = true;
                this.log('APP', `Application has stablised.`);
                this.setupCache();
                this.waitForSettings();
            });
        });
    }

    public get Bindings() {
        return this._composer.bindings;
    }
    /** Analytics service */
    public get Analytics() {
        return this._analytics;
    }
    /** Hotkeys service */
    public get Hotkeys() {
        return this._hotkeys;
    }

    /** Engine Composer service */
    public get Composer() {
        return this._composer;
    }

    /**
     * Get a setting from the settings service
     * @param key Name of the setting. i.e. nested items can be grabbed using `.` to seperate key names
     */
    public setting(key: string): any {
        return this._settings.get(key);
    }

    /** Name of the application */
    public get name() {
        return this._settings.app_name;
    }

    /**
     * Title of the page
     */
    public set title(value: string) {
        const title_suffix = this.setting('app.title');
        this.set('title', value);
        this._title.setTitle(`${value ? value + ' | ' : ''}${title_suffix}`);
    }

    /**
     * Title of the page
     */
    public get title(): string {
        return this._title.getTitle();
    }

    /**
     * Title for the top nav bar
     */
    public set navTitle(value: string) {
        this._navTitle = value;
    }

    public get navTitle(): string {
        return !!this._navTitle ? this._navTitle : '';
    }

    /** Root API Endpoint */
    public get endpoint() {
        return `/api/staff/`;
    }

    /** Root API Endpoint for engine */
    public get engine_endpoint() {
        return this._composer.auth.api_endpoint + '/';
    }

    /** Whether settings has been loaded */
    public get has_settings(): boolean {
        return this._settings.is_initialised;
    }

    /**
     * Create notification popup
     * @param type CSS Class to add to the notification
     * @param message Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     * @param icon Icon to render to the left of the notification message
     */
    public notify(
        type: string,
        message: string,
        action: string = 'OK',
        on_action?: () => void,
        icon: ApplicationIcon = {
            type: 'icon',
            class: 'material-icons',
            content: 'info'
        }
    ): void {
        const snackbar_ref = this._snackbar.open(message, action, {
            panelClass: [type],
            duration: 5000
        });
        this.subscription(
            'snackbar_close',
            snackbar_ref.afterDismissed().subscribe(() => {
                this.unsub('snackbar_close');
                this.unsub('notify');
            })
        );
        if (action) {
            on_action = on_action || (() => snackbar_ref.dismiss());
            this.subscription(
                'notify',
                snackbar_ref.onAction().subscribe(() => on_action())
            );
        }
    }

    /**
     * Create success notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifySuccess(msg: string, action?: string, on_action?: () => void): void {
        const icon: ApplicationIcon = {
            type: 'icon',
            class: 'material-icons',
            content: 'done'
        };
        this.notify('success', msg, action, on_action, icon);
    }

    /**
     * Create error notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifyError(msg: string, action?: string, on_action?: () => void): void {
        const icon: ApplicationIcon = {
            type: 'icon',
            class: 'material-icons',
            content: 'error'
        };
        this.notify('error', msg, action, on_action, icon);
    }

    /**
     * Create warning notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifyWarn(msg: string, action?: string, on_action?: () => void): void {
        const icon: ApplicationIcon = {
            type: 'icon',
            class: 'material-icons',
            content: 'warning'
        };
        this.notify('warn', msg, action, on_action, icon);
    }

    /**
     * Create info notification popup
     * @param msg Message to display on the notificaiton
     * @param action Display text for the callback action
     * @param on_action Callback of action on the notification
     */
    public notifyInfo(msg: string, action?: string, on_action?: () => void): void {
        this.notify('info', msg, action, on_action);
    }

    /**
     * Log data to the browser console
     * @param type Type of message
     * @param msg Message body
     * @param args array of argments to log to the console
     * @param stream Stream to emit the console on. 'debug', 'log', 'warn' or 'error'
     * @param force Whether to force message to be emitted when debug is disabled
     */
    public log(
        type: string,
        msg: string,
        args?: any,
        stream: ConsoleStream = 'debug',
        force: boolean = false
    ): void {
        this._settings.log(type, msg, args, stream, force);
    }

    /**
     * Get the current value of the named property
     * @param name Property name
     */
    public get<U = any>(name: string): U {
        return this._subjects[name] && this._subjects[name] instanceof BehaviorSubject
            ? (this._subjects[name] as BehaviorSubject<U>).getValue()
            : null;
    }

    /**
     * Listen to value change of the named property
     * @param name Property name
     * @param next Callback for value changes
     */
    public listen<U = any>(name: string, next: (_: U) => void): Subscription {
        return this._observers[name] ? this._observers[name].subscribe(next) : null;
    }

    /**
     * Update the value of the named property
     * @param name Property name
     * @param value New value
     */
    public set<U = any>(name: string, value: U): void {
        if (!this._subjects[name]) {
            this._subjects[name] = new BehaviorSubject<U>(value);
            this._observers[name] = this._subjects[name].asObservable();
        } else {
            this._subjects[name].next(value);
        }
    }

    /** Wait for settings to be initialised before setting up the application */
    private waitForSettings() {
        // Wait until the settings have loaded before initialising
        this._settings.initialised.pipe(first(_ => _)).subscribe(() => this.init());
    }

    /**
     * Initialise application services
     */
    private init(): void {
        this.set('loading', {});
        this.setupComposer();
        // Setup analytics
        this._analytics.enabled = !!this.setting('app.analytics.enabled');
        if (this._analytics.enabled) {
            this._analytics.load(this.setting('app.analytics.tracking_id'));
        }
        this._composer.initialised.pipe(first(_ => _)).subscribe(() => {
            this._initialised.next(true);
        });
        // Add service to window if in debug mode
        if (window.debug) {
            window.application = this;
        }
    }

    /**
     * Initialise the composer library comms
     */
    private setupComposer(): void {
        this.log('SYSTEM', 'Setup up composer...');
        const loading: ApplicationLoadingState = this.get('loading') || {};
        loading.composer = {
            message: 'Initialising service connection',
            state: 'loading'
        };
        this.set('loading', loading);
        // Get application settings
        const settings: ComposerSettings = this._settings.get('composer') || {};
        const protocol = settings.protocol || location.protocol;
        const host = settings.domain || location.hostname;
        const port = settings.port || location.port;
        const url = settings.use_domain ? `${protocol}//${host}:${port}` : location.origin;
        const route = settings.route || '';
        const mock = this._settings.get('mock');
        // Generate configuration object
        const config: PlaceOSOptions = {
            scope: 'public',
            host: `${host}${port ? ':' + port : ''}`,
            auth_uri: `${url}/auth/oauth/authorize`,
            token_uri: `${url}/auth/token`,
            redirect_uri: `${location.origin}${route}/oauth-resp.html`,
            handle_login: !settings.local_login,
            mock
        };
        this._composer.setup(config);
        loading.composer = {
            message: 'Initialising service connection',
            state: 'complete'
        };
        this.set('loading', loading);
    }

    /**
     * Setup handler for cache change events
     */
    private setupCache() {
        if (this._cache.isEnabled) {
            this.subscription(
                'cache_update',
                this._cache.available.subscribe(event => {
                    const current = `current version is ${event.current.hash}`;
                    const available = `available version is ${event.available.hash}`;
                    this.log('CACHE', `Update available: ${current} ${available}`);
                    this.activateUpdate();
                })
            );
            this.subscription(
                'cache_activated',
                this._cache.activated.subscribe(() => {
                    this.log('CACHE', `Updates activated. Reloading...`);
                    this.notifyInfo(
                        'Newer version of the application is available',
                        'Refresh',
                        () => location.reload(true)
                    );
                })
            );
            setInterval(() => {
                this.log('CACHE', `Checking for updates...`);
                this._cache.checkForUpdate();
            }, 5 * 60 * 1000);
        }
    }

    /**
     * Update the cache and reload the page
     *
     */
    private activateUpdate() {
        if (this._cache.isEnabled) {
            this.log('CACHE', `Activating changes to the cache...`);
            this._cache.activateUpdate().then(() => {
                this.notifyInfo('Newer version of the application is available', 'Refresh', () =>
                    location.reload(true)
                );
            });
        }
    }
}
