import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { MAT_LABEL_GLOBAL_OPTIONS, DateAdapter, MatNativeDateModule } from '@angular/material/core';

import './shared/mocks';

import { ComposerModule } from '@placeos/composer';
import { AGoogleAnalyticsModule } from '@acaprojects/ngx-google-analytics';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

import { SharedOverlaysModule } from './overlays/overlays.module';
import { SharedContentModule } from './shared/shared.module';

import { ShellWrapperComponent } from './shell/shell.component';
import { HomeComponent } from './shell/home/home.component';

@NgModule({
    declarations: [
        AppComponent,
        ShellWrapperComponent,
        HomeComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        HttpClientModule,
        FormsModule,
        ComposerModule,
        AGoogleAnalyticsModule,
        SharedOverlaysModule,
        SharedContentModule,
        MatNativeDateModule
    ],
    providers: [
        { provide: MAT_LABEL_GLOBAL_OPTIONS, useValue: {float: 'nevet'} }
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
