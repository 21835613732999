import { HashMap } from 'src/app/shared/utilities/types.utilities';
import { Space } from '../spaces/space.class';
import { ServiceManager } from '../service-manager.class';


export class Calendar {
    /** ID of the calendar */
    readonly id: string;
    /** Name of the calendar */
    readonly name: string;
    /** System associated with the calendar */
    readonly resource: Space;

    /** Service for managing Calendars */
    private _service(): any {
        ServiceManager.serviceFor(Calendar);
    }

    constructor(data: HashMap = {}) {
        this.id = data.id || '';
        this.name = data.name || '';
        this.resource = new Space(data.system)
    }
}
