import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ApplicationService } from 'src/app/services/app.service';
import { BaseDirective } from 'src/app/shared/base.directive';
import { Building } from 'src/app/services/data/organisation/building.class';
import { DialogEvent, Identity, HashMap } from 'src/app/shared/utilities/types.utilities';
import { OrganisationService } from 'src/app/services/data/organisation/organisation.service';
import { CalendarService } from 'src/app/services/data/calendar/calendar.service';

import * as dayjs from 'dayjs';
import { Space } from 'src/app/services/data/spaces/space.class';

export interface SpaceSelectModalData {
    /** List of currently selected spaces */
    readonly spaces: Space[];
    /** Selected date to check space availability */
    readonly date: number;
    /** Duration of the availability that is desired */
    readonly duration: number;
}

@Component({
    selector: 'a-space-select-modal',
    templateUrl: './space-select-modal.component.html',
    styleUrls: ['./space-select-modal.component.scss'],
})
export class SpaceSelectModalComponent extends BaseDirective implements OnInit {
    /** Emitter for user action on the modal */
    @Output() public event = new EventEmitter<DialogEvent>();
    /** List of selected spaces */
    public spaces: Space[] = [];
    /** List of available spaces */
    public available_spaces: Space[] = [];
    /** Building to filter available spaces */
    public building: Building;
    /** Whether to show selected spaces */
    public show_selected: boolean;
    /** List of available space types */
    public space_types: Identity[] = [];
    /** ID of the space type to filter available spaces on */
    public type: string;
    /** Whether the available spaces are being loaded */
    public loading: boolean;

    public get buildings(): Building[] {
        return this._org.buildings;
    }

    /** Whether multiple spaces can be selected */
    public get multiple(): boolean {
        return !!this._service.setting('app.booking.multiple_spaces');
    }

    /** Mapping of spaces to whether they are selected */
    public get space_map(): HashMap<boolean> {
        const spaces = {};
        this.spaces.forEach((space) => (spaces[space.id] = true));
        return spaces;
    }

    constructor(
        private _service: ApplicationService,
        private _org: OrganisationService,
        private _calendar: CalendarService,
        @Inject(MAT_DIALOG_DATA) private _data: SpaceSelectModalData
    ) {
        super();
    }

    public ngOnInit(): void {
        this.building = this._org.building || this.buildings[0];
        this.spaces = this._data.spaces && this._data.spaces.length ? [...this._data.spaces] : [];
        this.loadAvailableSpaces();
    }

    public loadAvailableSpaces() {
        this.loading = true;
        this.available_spaces = [];
        const date = dayjs(this._data.date);
        this._calendar.availability({
            zone_ids: this.building.id,
            period_start: date.unix(),
            period_end: date.add(this._data.duration, 'm').unix(),
        }).then((cal_list) => {
            this.available_spaces = cal_list.filter(cal => cal.resource).map(cal => cal.resource);
            this.loading = false;
        });
    }

    public spaceSelected(space: Space) {
        if (this.multiple) {
            if (!this.space_map[space.id]) {
                this.spaces.push(space);
            }
        } else {
            this.spaces = [space];
            this.save();
        }
    }

    public save() {
        this.event.emit({reason: 'done'});
    }
}
